import React from "react";

const TituloMenor = (props) => {
  return (
    <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-full lg:pb-32 xl:pb-32">
      <h2 className="text-sky-600 text-2xl font-bold mb-2 absolute top-6 left-6 border-b-4 border-amber-600 z-20">
        {props.titulo}
      </h2>
      <div className="md:w-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <svg
          className="block lg:hidden absolute left-0 inset-y-0 h-full w-96 text-white z-10"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="0,0 70,0 40,100 0,100" />
        </svg>
        <img
          src={props.imagen}
          className="h-32 w-full object-cover sm:h-32 md:h-28 md:w-2/5 md:float-right lg:w-full lg:h-full rounded-md shadow-md"
          alt={props.titulo}
        />
      </div>
    </div>
  );
};

export default TituloMenor;
