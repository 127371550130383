import React from "react";
import Navbar from "./Navbar";
import {
  ClockIcon,
  LocationMarkerIcon,
  PhoneIcon,
} from "@heroicons/react/outline";

const Header = () => {
  return (
    <div className="w-full">
      <div className="bg-white h-10 hidden lg:block">
        <div className="max-w-5xl mx-auto">
          <div className="flex justify-between">
            <div className="flex items-center mt-2">
              <ClockIcon className="h-5 w-5 mr-2" />
              <span className="text-gray-600 text-sm">
                Lunes a Viernes de 9:00 a 18:00
              </span>
            </div>
            <div className="flex items-center mt-2">
              <LocationMarkerIcon className="h-5 w-5 mr-2" />
              <span className="text-gray-600 text-sm">
                Av. Bernardo O'Higgins 0126, Puente Alto, Santiago.
              </span>
            </div>
            <div className="flex items-center mt-2">
              <a href="tel:+56232298219" className="flex text-sm text-gray-600">
                <PhoneIcon className="h-5 w-5 mr-2" />
                <span className="text-sm">+56 2 3229 8219</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky-900">
        <div className="max-w-5xl mx-auto">
          <div id="inicio"></div>
          <Navbar />
        </div>
      </div>
    </div>
  );
};

export default Header;
