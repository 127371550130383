import React from "react";

const Proyectos = () => {
  return (
    <div className="w-full bg-white py-6">
      <div className="max-w-5xl mx-auto">
        <div className="grid grid-cols-1 md:lg:grid-cols-2 gap-3">
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Sheraton Miramar</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Costanera Center</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Mall Barrio Independencia</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Edificio Manquehue</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Camara Chilena de la Construcción</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Edificio Britania</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Fiscalia Nacional (Santiago Centro)</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Centro Justicia Antofagasta</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Montemar Viña del Mar</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Cesfam Pudahuel</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Cesfam Puente Alto Vista Hermosa</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Edificio Indumotora</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Edificio Le Blanc</div>
          </div>
          <div className="flex shadow-sm bg-zinc-100 p-3 rounded-md m-2">
            <div className="hidden">imagen</div>
            <div>Clinica Davila</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proyectos;
