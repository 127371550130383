import React from 'react';
import { ClockIcon, LocationMarkerIcon, PhoneIcon } from '@heroicons/react/solid';

const Footer = () => {
    return (
        <div className="w-full bg-stone-800 relative">
            <div className="w-full h-4 bg-amber-600 absolute top-0 left-0"> </div>
            <div className="w-full md:lg:max-w-6xl mx-auto py-6">

                <div className="sm:px-6 sm:flex">
                    <div className="items-center mt-2 text-gray-100 w-full inline-flex justify-center">
                        <ClockIcon className="h-5 w-5 mr-2" />
                        <span className="text-sm">Lunes a Viernes de 9:00 a 18:00</span>
                    </div>
                    <div className="items-center mt-2 text-gray-100 w-full inline-flex justify-center">
                        <LocationMarkerIcon className="h-5 w-5 mr-2" />
                        <span className="text-sm">Av. Bernardo O'Higgins 0126, Puente Alto, Santiago.</span>
                    </div>
                    <div className="items-center mt-2 text-gray-100 w-full inline-flex justify-center">
                        <a href="tel:+56232298219" className="flex text-sm">
                            <PhoneIcon className="h-5 w-5 mr-2" />
                            <span className="text-sm">+56 2 3229 8219</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
