import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SliderHome from "../components/SliderHome";
import Soluciones from "../components/Soluciones";
import Proyectos from "../components/Proyectos";
import TituloMenor from "../components/tituloMenor";

const Inicio = () => {
  return (
    <div className="w-full">
      <Header />
      <SliderHome />
      <div className="w-full md:lg:max-w-4xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        <Soluciones />
        <TituloMenor titulo="Proyectos" imagen="assets/proyectos.jpg" />
        <p className="pt-6">
          Algunos de los proyectos en los que hemos colaborado
        </p>
        <Proyectos />
      </div>
      <Footer />
    </div>
  );
};

export default Inicio;
