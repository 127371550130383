import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Proyectos from "../components/Proyectos";
import TituloMenor from "../components/tituloMenor";

const Nosotros = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="w-full">
        <div className="w-full md:lg:max-w-4xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-sky-800 text-4xl font-bold mb-4">Nosotros</h1>
          <div className="border-b-2 border-dotted border-slate-200 my-6 pb-12">
            <p>
              Alto Aceros Ingeniería ltda, es una empresa especializada en la
              fabricación, modificación e importación de productos de acero
              inoxidable, acero carbono y aluminio.
            </p>
            <p>
              Fabricamos shower door, barandas, puertas de acceso, tabique
              vidriado y espejos.
            </p>
            <p>
              Contamos con equipo y personal capacitado para hacer instalaciones
              en terreno, con vasta experiencia en el rubro a nivel nacional.
            </p>
            <p>
              En caso de que esté interesado en nuestros productos y servicios,
              no dude en visitarnos y conocer personalmente nuestra empresa.
            </p>
          </div>
          <div className="">
            <TituloMenor titulo="Proyectos" imagen="assets/proyectos.jpg" />
            <div className="pt-6">
              <p>
                Esta es una pequeña lista de obras en las que hemos participado.
              </p>
              <p>
                También hemos trabajo en obras como strip centers, piscinas en
                edificios y casas, bancos, supermercados.
              </p>
              <p>
                Conversemos de su proyecto, para que le podamos dar la asesoría
                que necesita.
              </p>
              <Proyectos />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Nosotros;
