import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TituloMenor from "../components/tituloMenor";

const importados = [
  {
    name: "Tirador Pomo",
    title: "D = 30, L = 60mm.",
    department: "Terminación brillante",
    email: "",
    otro: "",
  },
  {
    name: "Conector M-CR",
    title: "45x45mm.",
    department: "",
    email: "",
    otro: "CR 8 - 12 mm",
  },
  {
    name: "Bisagra Shower M-CR",
    title: "89x65mm.",
    department: "Bisalado",
    email: "",
    otro: "CR 8 - 12 mm",
  },
  {
    name: "Codo Fijo",
    title: "D = 42mm, L = 55x55mm.",
    department: "Bruñido",
    email: "Tipo pasamano",
    otro: "",
  },
  {
    name: "Soporte sobre losa",
    title: "D = 50mm, L = 200mm.",
    department: "Bruñido",
    email: "Baranda, con placa de anclaje",
    otro: "CR 10 - 12mm",
  },
  {
    name: "Araña 4 puntas",
    title: "200mm.",
    department: "Terminación bruñida",
    email: "",
    otro: "",
  },
  {
    name: "Araña 2 puntas",
    title: "200mm.",
    department: "Terminación bruñida",
    email: "",
    otro: "",
  },
  {
    name: "Araña 1 puntas",
    title: "200mm.",
    department: "Terminación bruñida",
    email: "",
    otro: "",
  },
];

const Productos = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="w-full">
        <div className="w-full md:lg:max-w-4xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-sky-800 text-4xl font-bold mb-4">Productos</h1>
          <div className="border-b-2 border-dotted border-slate-200 my-6 pb-12">
            <TituloMenor titulo="Importados" imagen="assets/importados.jpg" />

            <div className="pt-6">
              <p>Importamos piezas de acero inoxidable o acero carbono.</p>
              <p>
                Además mantenemos un stock de las piezas mas requeridas, para
                shower door, puertas de acceso, tiradores etc.
              </p>
            </div>
          </div>

          <div className="">
            <TituloMenor titulo="Fabricados" imagen="assets/fabricados.jpg" />

            <div className="pt-6">
              <p>
                Podemos fabricar y modificar toda pieza de acero inoxidable o
                acero carbono.
              </p>
              <p>
                Pernos y arañas para muros cortina, barandas, balaustros,
                tiradores, cada pieza que necesite podemos fabricarla.
              </p>
              <p className="mb-2 font-semibold mt-2">
                Algunos de nuestros productos importados y/o fabricados:
              </p>
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 w-full">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Producto
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
                      >
                        Especificaciones
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
                      >
                        Información
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {importados.map((item) => (
                      <tr key={item.name}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {item.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {item.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 hidden md:table-cell">
                          <div className="text-sm text-gray-900">
                            {item.title}
                          </div>
                          <div className="text-sm text-gray-500">
                            {item.department}
                          </div>
                        </td>
                        <td className="px-6 py-4 hidden md:table-cell">
                          <div className="text-sm text-gray-900">
                            {item.otro}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Productos;
