import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Notfound = () => {
  return <div className="w-full">
      <Header />
      <div className="w-full">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <h1>Opps!</h1>
          <p>Pagina no encontrada.</p>
          <p>Puede tener un link desactualizado, utilice el menu superior para encontrar lo que busca.</p>
          <p>También puede comunicarse con nosotros por teléfono o e-mail.</p>
        </div>
      </div>
      <Footer />
  </div>;
};

export default Notfound;