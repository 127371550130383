import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useForm } from "react-hook-form";
import axios from "axios";

const Contacto = () => {
  /* datos del formulario */
  const formulario = {
    nombre: "",
    email: "",
    telefono: "",
    servicio: "",
    mensaje: "",
  };
  /* deshabilitar boton de envio, al enviar */
  const [deshabilitar, setDeshabilitar] = useState(false);
  const [mensaje, setMensaje] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setMensaje("Enviando mensaje, un momento por favor...");
    setDeshabilitar(true);
    axios({
      method: "post",
      url: "https://e85ikjssw0.execute-api.us-east-1.amazonaws.com/staging",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        console.log(result);
        setMensaje("Mensaje recibido, pronto nos pondremos en contacto");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="w-full">
      <Header />
      <div className="w-full bg-zinc-100 py-6">
        <div className="w-full md:lg:max-w-4xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <h2 className="text-sky-800 text-4xl font-bold mb-4">Contáctenos</h2>
          <p className="pt-6">
            Contáctenos y nuestros especialistas le atenderán de manera rápida,
            para que pueda completar su proyecto en tiempo y forma.
          </p>
          <p className="pt-6">
            Su nombre y correo electrónico serán utilizados para contactarle,
            nos pondremos en contacto con usted lo antes posible.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="sm:flex-col lg:flex lg:flex-row justify-between">
              <div className="w-full lg:w-1/2 p-4">
                <div>
                  <label className="block text-sm font-bold mb-2">Nombre</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="nombre"
                    name="nombre"
                    type="text"
                    placeholder="Nombre"
                    defaultValue={formulario.nombre}
                    {...register("nombre")}
                  />
                </div>
                <div className="py-3">
                  <label className="block text-sm font-bold mb-2">
                    Correo <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Correo"
                    defaultValue={formulario.email}
                    {...register("email", { required: "Ingrese su email" })}
                  />
                  {errors.email && (
                    <div className="mt-1 text-sm text-red-500 ">
                      {errors.email.message}
                    </div>
                  )}
                </div>
                <div className="py-3">
                  <label className="block text-sm font-bold mb-2">
                    Teléfono
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="telefono"
                    name="telefono"
                    type="text"
                    placeholder="Teléfono"
                    defaultValue={formulario.telefono}
                    {...register("telefono")}
                  />
                </div>
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <div className="py-3">
                  <label className="block text-sm font-bold mb-2">
                    Mensaje
                  </label>
                  <textarea
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="mensaje"
                    name="mensaje"
                    rows={9}
                    type="text"
                    placeholder="Mensaje"
                    defaultValue={formulario.mensaje}
                    {...register("mensaje")}
                  />
                </div>
                <div>
                  {mensaje && (
                    <div className="mt-1 text-md text-amber-500 ">
                      Mensaje enviado
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={deshabilitar}
                    className="bg-amber-500 hover:bg-amber-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacto;
