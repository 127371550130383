import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const listaSoluciones = [
  {
    id: 0,
    titulo: "Torno y Fresado",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/torno.jpg",
  },
  {
    id: 1,
    titulo: "Corte Plasma CNC",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/cortecnc.jpg",
  },
  {
    id: 2,
    titulo: "Galvanizado",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/galvanizado.jpg",
  },
  {
    id: 3,
    titulo: "Herrajes de Pasamanos",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/pasamanos.jpg",
  },
  {
    id: 4,
    titulo: "Cierres de terrazas y barandas",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/cierresbaranda.jpg",
  },
  {
    id: 5,
    titulo: "Cierres de Piscina",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/cierrespiscina.jpg",
  },
  {
    id: 6,
    titulo: "Anclajes",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/fabricados.jpg",
  },
  {
    id: 7,
    titulo: "Estructuras Metálicas",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/estructuras.jpg",
  },
  {
    id: 8,
    titulo: "Corte y Plegado",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/metalmecanica.jpg",
  },
  {
    id: 9,
    titulo: "Pasamanos e instalación",
    descripcion: "Soluciones de Metalmecánica",
    imagen: "assets/escaleras.jpg",
  },
];
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const Soluciones = ({ deviceType }) => {
  return (
    <div className="w-full bg-white py-6">
      <div className="w-full mx-auto">
        <Carousel
          partialVisibile
          infinite={true}
          autoPlay={deviceType !== "mobile" ? true : false}
          autoPlaySpeed={5000}
          deviceType={deviceType}
          itemClass="image-item"
          responsive={responsive}
        >
          {listaSoluciones.map((solucion) => (
            <div className="w-64 h-48 mx-auto px-1 relative" key={solucion.id}>
              <div className="text-center absolute top-2 z-10 w-full bg-white/50">
                <h3 className="text-stone-800 capitalize my-2  font-bold font-roboto text-base ">
                  {solucion.titulo}
                </h3>
              </div>
              <div className="relative z-0 ">
                <img
                  src={solucion.imagen}
                  className="rounded-md shadow-md"
                  alt={solucion.titulo}
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Soluciones;
