import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Soluciones from "../components/Soluciones";
import TituloMenor from "../components/tituloMenor";

const Servicios = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="w-full">
        <div className="w-full md:lg:max-w-4xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-sky-800 text-4xl font-bold mb-4">Servicios</h1>
          <div className="border-b-2 border-dotted border-slate-200 my-6 pb-12">
            <TituloMenor
              titulo="Metalmecánica"
              imagen="assets/metalmecanica.jpg"
            />
            <div className="pt-6">
              <p>
                El servicio de mecanizado cuenta con máquinas que nos permiten
                hacer diversos tipos de cortes, perforaciones y destajes.
                Gracias a esto podemos fabricar y/o modificar cada una de las
                piezas que necesite para su proyecto.
              </p>
              <p>
                Contamos con profesionales en cada una las áreas que se enfoca
                en dar una solución eficiente a sus requerimientos.
              </p>
            </div>
            <div className="w-full">
              <Soluciones />
            </div>
          </div>
          <div className="">
            <TituloMenor titulo="Instalación" imagen="assets/instalacion.jpg" />
            <div className="pt-6">
              <p>
                Nuestros instaladores especialistas, pueden ir a donde este su
                obra y cumplir en tiempo y forma con sus necesidades de
                instalación.
              </p>
              <p>
                El proceso puede comenzar con la fabricación en nuestra planta,
                para luego llevar todas las piezas hasta la obra y finalizar el
                trabajo de armado e instalación, de la manera mas precisa
                posible.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Servicios;
