import React from 'react';
import { Route, Routes } from "react-router-dom";
import Inicio from "./pages/Inicio";
import Nosotros from './pages/Nosotros';
import Servicios from './pages/Servicios';
import Productos from './pages/Productos';
import Contacto from './pages/Contacto';
import Notfound from './pages/Notfound';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="nosotros" element={<Nosotros />} />
      <Route path="servicios" element={<Servicios />} />
      <Route path="productos" element={<Productos />} />
      <Route path="contacto" element={<Contacto />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
}

export default App;
